/**
 * Code
 * @project Instrumentalverein Tüddern
 * @author  Marc Görtz <https://marcgoertz.de/>
 */

@charset 'utf-8';

/* inline code */
code {
	padding: 2px 1ex;
	border-radius: 3px;
	font-family: var(--font-stack-monospace);
	font-size: 1em;
	text-shadow: 0 1px 1px hsl(from var(--color-white) h s l / 75%);
	color: var(--color-gray);
	background-color: hsl(from var(--color-warmgray) h s 85%);
}

/* block code */
pre {
	margin: 2em 0;
	padding: 1em 2em;
	border-radius: 3px;
	max-block-size: 20em;
	overflow: auto; /* contain overflow in all browsers */
	font-family: var(--font-stack-monospace);
	font-size: 1em;
	text-shadow: 0 1px 1px hsl(from var(--color-white) h s l / 75%);
	color: var(--color-gray);
	background-color: hsl(from var(--color-warmgray) h s 85%);
	tab-size: 4;
	white-space: pre-wrap;
	word-wrap: break-word;
}

/**
 * Social Media Links
 * @project Instrumentalverein Tüddern
 * @author  Marc Görtz <https://marcgoertz.de/>
 */

@charset 'utf-8';

.social-media-links {
	display: inline-flex;
	gap: 1em;
	margin: 0;
	padding: 0;
	font-size: 1.75rem;
	line-height: 1em;
	list-style-type: none;
}

.social-media-links li {
	inline-size: 1em;
	block-size: 1em;
}

.social-media-links svg {
	inline-size: 100%;
	block-size: 100%;
	fill: currentcolor;
	vertical-align: top;
}

.social-media-links a:is(:link, :visited, :hover, :focus, :active) {
	display: block;
	inline-size: 100%;
	block-size: 100%;
}

.social-media-links a[href*='facebook']:is(:hover, :focus, :active) {
	color: var(--color-facebook);
	transition: color 0.2s var(--timing-linear);
}

.social-media-links a[href*='instagram']:is(:hover, :focus, :active) {
	color: var(--color-instagram);
	transition: color 0.2s var(--timing-linear);
}

.social-media-links a[href*='youtube']:is(:hover, :focus, :active) {
	color: var(--color-youtube);
	transition: color 0.2s var(--timing-linear);
}

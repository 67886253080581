/**
 * Highlighted Elements
 * @project Instrumentalverein Tüddern
 * @author  Marc Görtz <https://marcgoertz.de/>
 */

@charset 'utf-8';

.highlight {
	margin: 3rem -1rem;
	padding: 1rem;
	color: var(--color-gray);
	background-color: hsl(from var(--color-warmgray) h s 85%);
	box-sizing: border-box;
}

@supports (margin: env(safe-area-inset-left)) {
	.highlight {
		margin: 3rem calc(env(safe-area-inset-right) * -1 - 1rem) 3rem
			calc(env(safe-area-inset-left) * -1 - 1rem);
		padding: 1rem calc(env(safe-area-inset-right) + 1rem) 1rem
			calc(env(safe-area-inset-left) + 1rem);
	}
}

.highlight h2:first-child {
	margin-block-start: 0;
}

.highlight h2::after {
	border-block-start-color: var(--color-warmgray);
}

@media (--medium-devices-max) {
	main > .highlight:last-child {
		margin-block-end: -3rem;
	}
}

@media (--large-devices) {
	.highlight {
		border-radius: 6px;
	}
}

/**
 * Pull to refresh
 * @project Instrumentalverein Tüddern
 * @author  Marc Görtz <https://marcgoertz.de/>
 */

@charset 'utf-8';

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(359deg);
	}
}

#loader {
	position: absolute;
	inset-block-start: -4rem;
	inset-inline: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	block-size: 3rem;
	line-height: 3rem;
	opacity: 0;
	transition: all 0.4s var(--timing-linear);
}

#loader::before {
	content: '';
	border: 2px solid hsl(from var(--color-white) h s l / 25%);
	border-block-start-color: var(--color-white);
	border-radius: 100%;
	inline-size: 2rem;
	block-size: 2rem;
	box-sizing: border-box;
	animation: 1s var(--timing-linear) infinite spin;
}

.is-loading #loader {
	opacity: 1;
}

/**
 * Calendar
 * @project Instrumentalverein Tüddern
 * @author  Marc Görtz <https://marcgoertz.de/>
 */

@charset 'utf-8';

.calendar ol {
	margin: 0;
	padding: 0;
	list-style: none;
}

/* month headline */
.calendar-headline {
	position: relative;
	font-family: var(--font-stack);
	font-weight: normal;
	font-size: 0.875rem;
	text-align: center;
}

.calendar-headline::before {
	display: none;
}

.calendar-headline::after {
	position: absolute;
	inset-block-start: 50%;
	inset-inline: 0;
	z-index: 1;
	margin-block-start: 0;
	border-block-start-width: 1px;
	border-block-start-color: var(--color-warmgray);
	max-inline-size: none;
}

.calendar-headline span {
	position: relative;
	z-index: 2;
	padding: 0 1ex;
	background-color: var(--color-snowwhite);
}

/* single event */
.event {
	display: flex;
	align-items: center;
	margin: 0 0 2rem;
	fill: currentcolor;
}

.event:not(li) {
	margin-block-end: 1rem;
}

.event-date {
	flex-grow: 0;
	margin-inline-end: 1rem;
}

.event-date time {
	display: flex;
	flex-direction: column;
	border-radius: 6px;
	inline-size: 1.875em;
	color: var(--color-gray);
	background-color: var(--color-warmgray);
	font-size: 1.75em;
	text-align: center;
	overflow: hidden;
	box-sizing: border-box;
}

.event-date time .day {
	order: 2;
	font-family: var(--font-stack-serif);
	font-weight: 900;
}

.event-date time .month {
	order: 1;
	font-size: 0.5em;
	text-transform: uppercase;
	color: var(--color-white);
	background-color: var(--color-red);
}

.event-title {
	font-size: 1.125rem;
}

.event-description {
	font-size: 1rem;
}

.event-description svg {
	inline-size: 0.75em;
	block-size: 0.75em;
}

.event-location {
	margin-inline: 0.5em;
}

.event-weather {
	white-space: nowrap;
}

/* expired event */
.expired {
	opacity: 0.4;
	transition: opacity 0.2s var(--timing-linear);
}

@media (--supports-hover) {
	.expired:hover {
		opacity: 1;
	}
}

/* action buttons */
.action-buttons {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
}

.action-buttons .btn {
	margin: 0.25rem;
}

@media print {
	.event-date time {
		color: inherit;
		background-color: transparent;
		border: 1px solid currentcolor;
	}

	.event-date time .month {
		color: inherit;
		background-color: transparent;
	}

	.action-buttons {
		display: none;
	}
}

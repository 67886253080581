/**
 * Lists
 * @project Instrumentalverein Tüddern
 * @author  Marc Görtz <https://marcgoertz.de/>
 */

@charset 'utf-8';

/* staff members */
.staff-members {
	padding: 0;
	list-style-type: none;
}

.staff-members h3 {
	margin-block: 1rem -0.5em;
}

@media (--small-devices) {
	.staff-members {
		display: grid;
		grid-template: auto / repeat(2, minmax(170px, 1fr));
		gap: 1rem 0;
	}

	.staff-members h3 {
		margin-block-start: 0;
	}
}

/* links */
.links {
	padding: 0;
	list-style-type: none;
}

.links a:is(:link, :visited, :hover, :focus, :active) {
	display: inline-flex;
	align-items: center;
	gap: 1em;
}

.links img {
	flex-shrink: 0;
}

.links span {
	flex-grow: 0;
}

/* constitutional paragraphs */
.constitution {
	counter-reset: section paragraph;
}

.paragraphs h2::before {
	counter-increment: section;
	content: counter(section, upper-roman) '. ';
}

.paragraphs ol {
	padding: 0 0 0 2.5em;
	list-style-type: none;
}

.paragraphs ol > li {
	position: relative;
	counter-increment: paragraph;
}

.paragraphs ol > li::before {
	content: '§ ' counter(paragraph) '. ';
	position: absolute;
	inset-block-start: 0;
	inset-inline-start: -2.5em;
	inline-size: 2.5em;
}

.paragraphs ol p {
	margin: 0 0 1em;
}

.paragraphs ol ul {
	margin: 0 0 1em;
	padding: 0 0 0 1.5em;
	list-style-type: disc;
}

/* timeline */
.timeline {
	margin: 0 -1rem;
	padding: 0;
	list-style-type: none;
}

.timeline li {
	display: flex;
	align-items: center;
	position: relative;
}

.timeline li::before {
	content: '';
	position: absolute;
	inset-block-start: auto;
	inset-inline-start: 150px;
	z-index: 3;
	margin: 0 0 0 -5px;
	inline-size: 13px;
	block-size: 13px;
	border-radius: 100%;
	box-shadow: 0 0 10px var(--color-warmgray);
	background-color: currentcolor;
}

.timeline li:first-child::after {
	content: '';
	position: absolute;
	inset-block-start: 0;
	inset-inline-start: 150px;
	z-index: 2;
	inline-size: 3px;
	block-size: 50%;
	background-color: hsl(from var(--color-warmgray) h s 85%);
}

.timeline li:last-child::before {
	box-shadow: 0 0 10px hsl(from var(--color-red) h s l / 50%);
	background-color: var(--color-red);
}

.timeline li:last-child::after {
	content: '';
	position: absolute;
	inset-block-start: 50%;
	inset-inline-start: 150px;
	z-index: 2;
	inline-size: 3px;
	block-size: 50%;
	background-image: linear-gradient(
		to bottom,
		var(--color-red),
		hsl(from var(--color-warmgray) h s 85%)
	);
}

.timeline time {
	flex-shrink: 0;
	flex-basis: 150px;
	padding: 0 2rem 0 0;
	text-align: end;
	box-sizing: border-box;
}

.timeline time span {
	display: none;
}

.timeline strong {
	flex-shrink: 1;
	flex-grow: 1;
	padding: 1rem 1rem 1rem 2rem;
	border-inline-start: 3px solid var(--color-warmgray);
	font-weight: normal;
	text-align: start;
	color: inherit;
	background-color: hsl(from var(--color-warmgray) h s 85%);
}

@media (--medium-devices) {
	.timeline strong {
		font-size: 1.25rem;
	}
}

@supports (display: grid) {
	@media (--small-devices-max) {
		.timeline {
			display: flex;
			overflow: auto;
			scroll-snap-type: x proximity;
		}

		.timeline:last-child {
			margin-block-end: -3rem;
		}

		.timeline li {
			flex-shrink: 0;
			display: grid;
			grid-template-rows: 50px 1fr;
			min-inline-size: 150px;
			scroll-snap-align: center;
			scroll-padding: 50%;
		}

		.timeline li::before {
			inset-block-start: 50px;
			inset-inline-start: 50%;
			margin-block-start: -5px;
		}

		.timeline li:first-child::after {
			inset-block-start: 50px;
			inset-inline-start: 0;
			inline-size: 50%;
			block-size: 3px;
		}

		.timeline li:last-child::after {
			inset-block-start: 50px;
			inset-inline-start: 50%;
			inline-size: 50%;
			block-size: 3px;
			background-image: linear-gradient(
				to right,
				var(--color-red),
				hsl(from var(--color-warmgray) h s 85%)
			);
		}

		.timeline time {
			padding-inline-end: 0;
			text-align: center;
		}

		.timeline strong {
			padding-inline-start: 1rem;
			padding-block-end: 3rem;
			border-block-start: 3px solid var(--color-warmgray);
			border-inline-start: 0;
			font-size: 1.25rem;
			text-align: center;
		}
	}
}

/* years */
.years {
	display: flex;
	gap: 0.5em 1em;
	margin: 0;
	padding: 1rem 0 0.5em;
	list-style-type: none;
	overflow: auto;
	scroll-snap-type: x proximity;
}

.years li {
	flex-shrink: 0;
	flex-grow: 0;
	scroll-snap-align: start;
	scroll-padding: 50%;
}

.years a:is(:link, :visited, :hover, :focus, :active) {
	display: block;
	padding: 0.125em 0.75em;
	border-radius: 2em;
	font-weight: normal;
	text-decoration: none;
	color: var(--color-white);
	background-color: var(--color-red);
	box-shadow: none;
	transition: background-color 0.2s var(--timing-linear);
}

@media (--supports-hover) {
	.years a:hover {
		background-color: var(--color-mediumred);
	}
}

.years span {
	display: block;
	padding: 0.125em 0.75em;
	border-radius: 2em;
	color: var(--color-gray);
	background-color: var(--color-warmgray);
}

@media (--large-devices) {
	.years {
		flex-wrap: wrap;
		justify-content: center;
		overflow: visible;
	}
}

/* striped list */
.striped {
	margin: 0 0 1rem;
	padding: 0;
	line-height: 1.25;
	list-style-type: none;
}

.striped li {
	padding: 0.25rem 0.5rem;
}

.striped li:nth-child(even) {
	background-color: hsl(from var(--color-warmgray) h s 95%);
}

.striped a:is(:link, :visited, :hover, :focus, :active) {
	display: block;
	color: inherit;
	text-decoration: none;
}

.striped small {
	font-weight: normal;
}

.striped .error {
	color: var(--color-red);
}

.striped .error svg {
	fill: currentcolor;
	inline-size: 1em;
	block-size: 1em;
}

/* tag cloud */
.cloud {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
	margin: 0;
	padding: 0;
	list-style-type: none;
}

.cloud li[data-weight='1'] {
	--size: 1;
}

.cloud li[data-weight='2'] {
	--size: 2;
}

.cloud li[data-weight='3'] {
	--size: 3;
}

.cloud li[data-weight='4'] {
	--size: 4;
}

.cloud li[data-weight='5'] {
	--size: 5;
}

.cloud li[data-weight='6'] {
	--size: 6;
}

.cloud li[data-weight='7'] {
	--size: 7;
}

.cloud li[data-weight='8'] {
	--size: 8;
}

.cloud li[data-weight='9'] {
	--size: 9;
}

.cloud li {
	--size: 4;

	margin: 5px;
	font-size: calc(var(--size) * 0.25rem + 0.75rem);
}

/* link block list */
.link-blocks {
	display: grid;
	grid-template: auto / 1fr;
	gap: 1em;
	margin: 1rem 0;
	padding: 0;
	text-align: center;
	list-style-type: none;
}

@media (--medium-devices) {
	.link-blocks {
		grid-template: auto / repeat(2, 1fr);
	}
}

.link-blocks svg {
	margin-inline-end: 0.5rem;
	inline-size: 1.75em;
	block-size: 1.75em;
	fill: currentcolor;
}

.link-blocks svg[fill='none'] {
	fill: none;
	stroke: currentcolor;
	stroke-width: 2px;
}

.link-blocks a:is(:link, :visited, :hover, :focus, :active) {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 1rem;
	border-radius: 6px;
	color: inherit;
	background-color: var(--color-warmgray);
	text-decoration: none;
	transition-property: color, background-color;
	transition-duration: 0.2s;
	transition-timing-function: linear;
}

@media (--supports-hover) {
	.link-blocks a:hover {
		color: var(--color-white);
		background-color: var(--color-red);
	}

	.link-blocks a[href*='facebook']:hover {
		color: var(--color-white);
		background-color: var(--color-facebook);
	}

	.link-blocks a[href*='instagram']:hover {
		color: var(--color-white);
		background-color: var(--color-instagram);
	}

	.link-blocks a[href*='youtube']:hover {
		color: var(--color-white);
		background-color: var(--color-youtube);
	}
}

/* definition lists */
dl {
	display: grid;
	grid-template: auto / auto 1fr;
	gap: 0.5rem 1rem;
}

dl dd {
	margin: 0;
}

/* small/quiet items */
li.quiet {
	list-style-type: none;
}

@media print {
	.years {
		display: none;
	}
}

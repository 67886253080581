/**
 * Navigation
 * @project Instrumentalverein Tüddern
 * @author  Marc Görtz <https://marcgoertz.de/>
 */

@charset 'utf-8';

/* navigation */
nav {
	display: block;
}

/* main navigation */
.main-nav {
	grid-area: nav;
	display: block; /* correct `block` display not defined in IE 8/9/10/11. */
	text-align: center;
}

.main-nav a:is(:link, :visited, :hover, :focus, :active) {
	color: inherit;
}

/* social media links */
.main-nav .social-media-links {
	margin-block-start: 2rem;
	margin-inline-end: 35px;
	color: currentcolor;
	font-size: 1.25rem;
}

/* navigation list */
.nav-list {
	margin: 0;
	padding: 0;
	list-style: none;
}

.nav-list svg {
	inline-size: 1em;
	block-size: 1em;
	fill: currentcolor;
	vertical-align: -0.125em;
}

.nav-list a:is(:link, :visited, :hover, :focus, :active) {
	display: inline-block;
	text-decoration: none;
	text-transform: uppercase;
	font-weight: bold;
}

/* sub navigation */
.nav-list .sub-nav-list {
	display: none;
	margin: 0 0 0.5rem;
	padding: 0;
	list-style: none;
}

.nav-list .sub-nav-list a:is(:link, :visited, :hover, :focus, :active) {
	display: inline;
	padding: 0;
	font-size: 0.875em;
	text-decoration: none;
	text-transform: none;
	font-weight: normal;
}

.nav-list .sub-nav-list .link-button {
	color: inherit;
	font-size: 0.875em;
	text-decoration: none;
	text-transform: none;
	font-weight: normal;
}

.section-2 #nav-section-2 .sub-nav-list,
.section-5 #nav-section-5 .sub-nav-list,
.section-8 #nav-section-8 .sub-nav-list {
	display: block;
}

/* lava effect */
#lava {
	position: absolute;
	inset-block-start: 0;
	inset-inline-end: 0;
	inline-size: 3px;
	block-size: auto;
	color: var(--color-white);
	background: var(--color-red);
	transition: all 0.2s var(--timing-linear);
	pointer-events: none;
}

/* skip link */
#skiplink {
	position: fixed;
	inset-block-start: 0;
	inset-inline-end: 0;
	z-index: 101;
	inline-size: 48px;
	block-size: 48px;
	text-decoration: none;
	background-color: transparent;
	box-shadow: none;
	overflow: hidden;
}

@supports (inset-inline-end: env(safe-area-inset-right)) {
	#skiplink {
		inset-inline-end: env(safe-area-inset-right);
	}
}

#skiplink span:is(:first-child, :last-child) {
	position: absolute;
	inset: 9px;
	transform: none;
	transition: transform 0.2s var(--timing-fast-ease-out);
}

#skiplink span:is(:first-child, :last-child)::before {
	content: '';
	position: absolute;
	inset-inline: 7px 6px;
	block-size: 1px;
	border-radius: 0.5px;
	background-color: var(--color-snowwhite);
	transition: transform 0.15s ease-in-out 0.1s;
}

#skiplink span:first-child::before {
	inset-block-start: 14px;
	transform: translateY(-3px);
}

#skiplink span:last-child::before {
	inset-block-end: 14px;
	transform: translateY(3px);
}

@media (--medium-devices-max) {
	.main-nav .social-media-links {
		display: none;
	}

	.nav-list {
		position: fixed;
		inset-block-start: 0;
		inset-inline: 0;
		z-index: 100;
		padding-block-start: 48px;
		block-size: 48px;
		overflow: hidden;
		color: var(--color-white);
		background-image: linear-gradient(
			to bottom,
			var(--color-darkred),
			var(--color-mediumred)
		);
		box-sizing: border-box;
		transition: height 0.5s var(--timing-fast-ease-in-ease-out);
	}

	.nav-list a:is(:link, :visited, :hover, :focus, :active) {
		margin-block-end: 1em;
	}

	.nav-list > li {
		opacity: 0;
		transform: scale(1.1) translateY(-1.5em) translateZ(0);
		transition-property: opacity, transform;
		transition-duration: 0.25s;
		transition-timing-function: ease-out;
	}

	.nav-list > li:nth-child(1) {
		transition-delay: 0.25s;
	}

	.nav-list > li:nth-child(2) {
		transition-delay: 0.3s;
	}

	.nav-list > li:nth-child(3) {
		transition-delay: 0.35s;
	}

	.nav-list > li:nth-child(4) {
		transition-delay: 0.4s;
	}

	.nav-list > li:nth-child(5) {
		transition-delay: 0.45s;
	}

	.nav-list > li:nth-child(6) {
		transition-delay: 0.5s;
	}

	.nav-list > li:nth-child(7) {
		transition-delay: 0.55s;
	}

	.nav-list > li:nth-child(8),
	.nav-list > li:nth-child(9) {
		transition-delay: 0.6s;
	}

	.nav-list > li:first-child {
		margin-block-start: 72px;
	}

	.nav-list .sub-nav-list {
		margin-block: -0.5rem 1rem;
	}

	#lava {
		display: none;
	}

	.with-nav {
		position: fixed;
		inline-size: 100%;
		block-size: 100%;
		overflow: hidden;
	}

	.with-nav .nav-list {
		block-size: 100%;
		overflow: auto;
	}

	@supports (padding-block-end: env(safe-area-inset-bottom)) {
		.with-nav .nav-list {
			padding-block-end: env(safe-area-inset-bottom);
		}
	}

	.with-nav .nav-list > li {
		opacity: 1;
		transform: none;
	}

	.with-nav #skiplink span:first-child {
		transform: rotate(45deg);
		transition: transform 0.3s var(--timing-fast-ease-out) 0.1s;
	}

	.with-nav #skiplink span:last-child {
		transform: rotate(-45deg);
		transition: transform 0.3s var(--timing-fast-ease-out) 0.1s;
	}

	.with-nav #skiplink span:is(:first-child, :last-child)::before {
		transform: none;
		transition: transform 0.2s var(--timing-fast-ease-out);
	}
}

@media (--large-devices) {
	.main-nav {
		position: relative;
		margin: 0 0 1rem;
		text-align: end;
		background-color: var(--color-snowwhite);
	}

	.nav-list {
		padding: 0.5em 35px 0.5em 0;
		border-inline-end: 2px solid var(--color-warmgray);
	}

	.nav-list a:is(:link, :visited, :hover, :focus, :active) {
		display: block;
		padding: 0.5em 0;
	}

	#skiplink {
		display: none;
	}
}

/* sticky navigation */
.sticky {
	position: sticky;
	inset-block-start: 48px;
	z-index: 1;
	margin-inline: -1rem;
	padding-block-start: 1.5rem;
	padding-inline: 1rem;
	background-color: var(--color-snowwhite);
}

.sticky::after {
	content: '';
	position: absolute;
	inset-block-start: 100%;
	inset-inline: 0;
	block-size: 1em;
	background-image: linear-gradient(
		to bottom,
		var(--color-snowwhite),
		hsl(from var(--color-snowwhite) h s l / 0%)
	);
}

@supports (-webkit-backdrop-filter: blur(10px)) {
	.sticky {
		background-color: hsl(from var(--color-snowwhite) h s l / 10%);
		-webkit-backdrop-filter: blur(10px) grayscale(100%); /* stylelint-disable-line property-no-vendor-prefix */
	}

	.sticky::after {
		display: none;
	}

	.sticky > *:first-child {
		padding-inline: 1rem;
	}
}

@media (--large-devices) {
	.sticky {
		inset-block-start: 0;
		margin: 0;
		padding: 0;
	}
}

@media print {
	.main-nav {
		display: none;
	}

	.sticky {
		position: static;
	}
}

@media (prefers-reduced-motion: reduce) {
	.nav-list,
	.nav-list > li,
	#lava,
	#skiplink span:is(:first-child, :last-child),
	#skiplink span:is(:first-child, :last-child)::before,
	.with-nav #skiplink span:is(:first-child, :last-child),
	.with-nav #skiplink span:is(:first-child, :last-child)::before {
		transition: none;
	}
}

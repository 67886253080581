/**
 * Footer
 * @project Instrumentalverein Tüddern
 * @author  Marc Görtz <https://marcgoertz.de/>
 */

@charset 'utf-8';

.page-footer {
	position: relative;
	padding: 2rem 1rem 3rem;
	font-size: 0.875rem;
	line-height: 1.75;
	color: var(--color-warmgray);
	background: var(--color-black);
	clear: both;
	contain: paint;
}

@supports (padding: env(safe-area-inset-left)) {
	.page-footer {
		padding: 2rem calc(env(safe-area-inset-right) + 1rem) 3rem
			calc(env(safe-area-inset-left) + 1rem);
	}
}

.page-footer section {
	margin: 0 0 1.5rem;
	overflow: hidden;
}

.page-footer h3 {
	margin: 0;
	font-family: var(--font-stack);
	font-size: 1em;
	font-weight: bold;
	text-transform: uppercase;
	color: var(--color-white);
}

.page-footer h3 + p {
	margin: 0;
}

.page-footer a:is(:link, :visited) {
	color: inherit;
	font-weight: normal;
	text-decoration: none;
}

.page-footer a:is(:hover, :focus, :active) {
	color: inherit;
	font-weight: normal;
	text-decoration: underline;
}

.footer-address {
	grid-row: 1;
	grid-column: 1;
}

.footer-contact {
	grid-row: 2;
	grid-column: 1;
}

.footer-contact .social-media-links {
	margin-block-start: 0.625rem;
}

.footer-rehearsals {
	grid-row: 1 / span 2;
	grid-column: 2;
}

.footer-rehearsals span {
	display: block;
}

.footer-rehearsals ul {
	margin: 0;
	padding: 0;
	list-style: none;
	overflow: hidden;
}

.footer-legal {
	grid-row: 3;
	grid-column: 1 / span 2;
}

.footer-legal p {
	margin-block-start: 0;
}

.footer-legal .heart {
	color: var(--color-red);
}

.footer-legal ul {
	display: flex;
	flex-wrap: wrap;
	margin: 0;
	padding: 0;
	list-style: none;
}

.footer-legal li {
	display: inline-flex;
}

.footer-legal li:not(:last-child)::after {
	content: '\00A0•\00A0';
}

@media (--small-devices) {
	.footer-rehearsals span {
		display: inline-block;
		inline-size: 7em;
	}
}

@media (--large-devices) {
	.page-footer .wrapper {
		margin-inline: auto;
		max-inline-size: 800px;
	}

	.footer-rehearsals {
		position: absolute;
		inset-block-start: 2rem;
		inset-inline-start: 50%;
	}

	@supports (display: grid) {
		.page-footer .wrapper {
			display: grid;
			grid-template: auto / repeat(2, 50%);
		}

		.footer-rehearsals {
			position: static;
		}
	}
}

@media print {
	.page-footer {
		display: none;
	}
}

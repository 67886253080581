/**
 * Event Landingpages
 * @project Instrumentalverein Tüddern
 * @author  Marc Görtz <https://marcgoertz.de/>
 */

@charset 'utf-8';

@font-face {
	src:
		resolve('intro-inline.woff2') format('woff2'),
		resolve('intro-inline.woff') format('woff');
	font-family: 'Intro Inline';
	font-weight: normal;
	font-style: normal;
}

/* event landingpages */
.landingpage {
	position: relative;
	margin: 0 auto 2rem;
	border-radius: 6px;
	max-inline-size: 600px;
	overflow: hidden;
	box-sizing: border-box;
}

.landingpage-concert {
	padding-block-end: 2em;
	font-size: 1.125rem;
	font-size: clamp(0.5rem, 3vw, 1.125rem);
	color: hsl(0deg 0% 100%);
	background-color: hsl(0deg 0% 0%);
}

.landingpage-concert h1 {
	position: absolute;
	inset-block-start: 0.75em;
	inset-inline: 0;
	margin: 0;
	font-size: 1.125em;
	font-weight: bold;
	font-family: inherit;
	letter-spacing: 0.25ex;
	text-transform: uppercase;
	text-align: center;
	text-shadow: 0 0 1ex hsl(0deg 0% 0% / 50%);
	color: hsl(0deg 0% 100%);
}

.landingpage-concert h1 ~ *:not(.landingpage-image) {
	margin-inline: 1.5em;
}

.landingpage-concert ul {
	margin: 1em 1.5em 2em;
	padding: 0;
	list-style-type: none;
}

.landingpage-concert ul li {
	margin-block-end: 0.75em;
}

.landingpage-concert time {
	font-size: 1.75em;
	font-weight: bold;
	text-transform: uppercase;
}

.landingpage-concert time span {
	display: inline-block;
	margin: 0 0.25em;
}

.landingpage-childrenmarket {
	font-family: system-ui;
	font-size: 1.5rem;
	font-size: clamp(1rem, 2.5vw, 1.5rem);
	line-height: 1.25;
	text-align: start;
	color: hsl(24deg 31% 51%);
	background-color: hsl(53deg 100% 97%);
	box-sizing: border-box;
}

.landingpage-childrenmarket p {
	margin: 0;
	padding: 0 1.5625em 1em;
	color: hsl(0deg 0% 100%);
	background-color: hsl(359deg 68% 18%);
}

.landingpage-childrenmarket .flip-card {
	position: absolute;
	inset-block-end: 5em;
	inset-inline-end: 1em;
	padding: 0;
	inline-size: 7.25em;
	block-size: 7.25em;
	font-size: 0.75em;
	font-weight: bold;
	text-align: center;
	transform: rotate(-15deg);
}

.landingpage-childrenmarket .flip-card p {
	position: relative;
	padding: 0;
	transition: transform 0.8s;
	transform-style: preserve-3d;
}

@media (--supports-hover) {
	.landingpage-childrenmarket .flip-card:hover p {
		transform: rotateY(180deg);
	}

	@media (prefers-reduced-motion: reduce) {
		.landingpage-childrenmarket .flip-card:hover p {
			transform: none;
		}
	}
}

.landingpage-childrenmarket .flip-card svg {
	margin-block-start: -5%;
	inline-size: 65%;
	block-size: auto;
}

.landingpage-childrenmarket .flip-card span {
	position: absolute;
	z-index: 1;
	inline-size: 7.25em;
	block-size: 7.25em;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0.25em;
	border: 0.25em solid currentcolor;
	border-radius: 50%;
	box-sizing: border-box;
	fill: currentcolor;
	background-color: hsl(0deg 0% 100% / 25%);
	backface-visibility: hidden;
}

.landingpage-childrenmarket .flip-card span:last-child {
	transform: rotateY(180deg);
}

.landingpage-childrenmarket h1 + p {
	margin-block-start: -0.75em;
	padding: 0.75em 1.25em;
	font-size: 1.25em;
}

@supports (clip-path: ellipse(120% 135% at 55% 135%)) {
	.landingpage-childrenmarket h1 + p {
		margin-block-start: -1em;
		padding-block-start: 1em;
		clip-path: ellipse(120% 135% at 55% 135%);
	}
}

.landingpage-childrenmarket img {
	inline-size: 100%;
	max-inline-size: none;
}

.landingpage-fleamarket {
	padding: 1rem;
	font-size: 1.5rem;
	font-size: clamp(1rem, 2.5vw, 1.5rem);
	color: hsl(0deg 0% 100%);
	background-color: hsl(17deg 100% 45%);
	background-image:
		resolve('events/flohmarkt.svg'),
		linear-gradient(to bottom, hsl(17deg 100% 45%), hsl(17deg 100% 40%));
	background-repeat: no-repeat;
	background-position: 100% 60%;
	background-size: contain;
}

@media (--supports-p3) {
	.landingpage-fleamarket {
		background-color: color(display-p3 0.805 0.307 0);
		background-image:
			resolve('events/flohmarkt.svg'),
			linear-gradient(
				to bottom,
				color(display-p3 0.805 0.307 0),
				color(display-p3 0.744 0.283 0)
			);
	}
}

.landingpage-fleamarket h1 {
	margin-block-end: 0;
	font-family: 'Intro Inline', Impact, sans-serif;
	font-weight: normal;
	font-size: 1.75em;
	font-size: clamp(1.75em, 2.25vw + 1em, 2.25em);
	line-height: 1.125;
	text-align: start;
}

.landingpage-fleamarket p:last-child {
	margin-block-start: 13em;
}

.landingpage-fair .fair-title {
	padding: 10px 15px;
	border-radius: 6px;
	font-size: 1.5rem;
	font-size: clamp(1rem, 2.5vw, 1.5rem);
	font-weight: bold;
	text-align: center;
	color: hsl(0deg 0% 0%);
	background-color: hsl(0deg 0% 93%);
	background-image: linear-gradient(
		to bottom,
		transparent,
		hsl(0deg 0% 0% / 10%)
	);
}

.landingpage-fair h1 {
	margin: 0;
	font-size: 1em;
}

.landingpage-fair ol {
	margin: 0;
	padding: 0;
	list-style-type: none;
}

.landingpage-fair svg {
	vertical-align: bottom;
}

.landingpage-fair .fair-day {
	display: flex;
	align-items: center;
	margin: 10px 0 0;
	border-radius: 6px;
	text-align: center;
	color: hsl(0deg 0% 0%);
	background-color: hsl(0deg 0% 93%);
	background-image: linear-gradient(
		to bottom,
		transparent,
		hsl(0deg 0% 0% / 15%)
	);
	overflow: hidden;
}

.landingpage-fair .fair-day svg {
	inline-size: 100%;
	block-size: 100%;
}

.landingpage-fair .fair-day time {
	flex: 0 0 20%;
}

.landingpage-fair .fair-day span {
	flex-grow: 1;
	flex-shrink: 1;
}

.landingpage-fair .fair-thursday {
	background-color: hsl(195deg 100% 45%);
}

.landingpage-fair .fair-friday {
	background-color: hsl(113deg 100% 45%);
}

.landingpage-fair .fair-saturday {
	background-color: hsl(335deg 100% 45%);
}

.landingpage-fair .fair-saturday span {
	fill: hsl(0deg 0% 100%);
	background-color: hsl(0deg 0% 0%);
}

.landingpage-fair .fair-sunday {
	background-color: hsl(54deg 100% 45%);
}

@media (--supports-p3) {
	.landingpage-fair .fair-thursday {
		background-color: color(display-p3 0.235 0.615 1);
	}

	.landingpage-fair .fair-friday {
		background-color: color(display-p3 0.51 0.9 0.185);
	}

	.landingpage-fair .fair-saturday {
		background-color: color(display-p3 0.9 0.15 0.485);
	}

	.landingpage-fair .fair-sunday {
		background-color: color(display-p3 0.985 0.93 0.18);
	}
}

@media print {
	.landingpage-fair .fair-saturday span {
		fill: currentcolor;
		background-color: transparent;
	}
}

/**
 * Popovers
 * @project Instrumentalverein Tüddern
 * @author  Marc Görtz <https://marcgoertz.de/>
 */

@charset 'utf-8';

@keyframes bounce-in {
	0% {
		opacity: 0;
		transform: scale3d(0.3, 0.3, 0.3);
		animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
	}

	20% {
		transform: scale3d(1.1, 1.1, 1.1);
		animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
	}

	40% {
		transform: scale3d(0.9, 0.9, 0.9);
		animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
	}

	60% {
		opacity: 1;
		transform: scale3d(1.03, 1.03, 1.03);
		animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
	}

	80% {
		transform: scale3d(0.97, 0.97, 0.97);
		animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
	}

	100% {
		opacity: 1;
		transform: scale3d(1, 1, 1);
		animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
	}
}

/* popover */
.popover {
	display: none;
	z-index: 3;
}

/* triggers */
.js [aria-haspopup][aria-controls] {
	cursor: pointer;
}

.js .btn[aria-haspopup]::after {
	content: '';
	display: inline-block;
	margin-inline-start: 0.25em;
	vertical-align: 0.15em;
	border-block-start: 0.35em solid currentcolor;
	border-inline: 0.35em solid transparent;
	border-block-end: 0;
}

/* actual popover */
.popover .popover-inner {
	display: block;
	position: relative;
	margin: 6px 0 0;
	padding: 0.5rem 1rem;
	border-radius: 6px;
	font-size: 0.75rem;
	text-align: start;
	color: var(--color-white);
	background-color: hsl(from var(--color-black) h s l / 85%);
	list-style-type: none;
}

.popover .popover-inner::before {
	content: '';
	position: absolute;
	inset-block-end: 100%;
	inset-inline-start: 50%;
	margin-inline-start: -6px;
	border: 6px solid transparent;
	border-block-end-color: hsl(from var(--color-black) h s l / 85%);
	inline-size: 0;
	block-size: 0;
	pointer-events: none;
}

.popover .popover-inner a:is(:link, :visited, :hover, :focus, :active) {
	display: inline-block;
	margin: 0.125em 0;
	padding: 0.125em 0;
	color: inherit;
	font-weight: normal;
	text-decoration: none;
}

.popover .popover-inner svg {
	display: inline-block;
	margin-inline-end: 0.25ex;
	inline-size: 1em;
	block-size: 1em;
	fill: currentcolor;
	vertical-align: -0.125em;
}

/* map */
.map {
	border-radius: 6px;
	box-shadow: 0 0 3em var(--color-shadow);
	overflow: hidden;
}

/* visible popovers */
.popover[aria-hidden='false'] {
	display: block;
}

.popover[aria-hidden='false'] :is(.popover-inner, .map) {
	animation-duration: 0.75s;
	animation-name: bounce-in;
}

.popover[aria-hidden='false'][data-popper-placement='top']
	:is(.popover-inner, .map) {
	transform-origin: 50% 100%;
}

.popover[aria-hidden='false'][data-popper-placement='bottom']
	:is(.popover-inner, .map) {
	transform-origin: 50% 0;
}

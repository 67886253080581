/**
 * Obituaries
 * @project Instrumentalverein Tüddern
 * @author  Marc Görtz <https://marcgoertz.de/>
 */

@charset 'utf-8';

.obituary {
	position: relative;
	padding: 4em 1em 1em;
	border: 0.25em solid var(--color-black);
	font-family: sans-serif;
	text-align: justify;
	text-justify: inter-character;
	hyphens: auto;
	color: var(--color-black);
	background: var(--color-white);
}

.obituary::before {
	content: '';
	position: absolute;
	inset-block-start: 1.6em;
	inset-inline: 0;
	margin: 0 auto;
	inline-size: 1.5em;
	block-size: 0.35em;
	background-color: currentcolor;
}

.obituary::after {
	content: '';
	position: absolute;
	inset-block-start: 1em;
	inset-inline: 0;
	margin: auto;
	inline-size: 0.35em;
	block-size: 2.25em;
	background-color: currentcolor;
}

.obituary strong {
	display: block;
	margin: 1rem auto;
	font-size: 1.5em;
	text-align: center;
}

@media print {
	.obituary {
		padding-block-start: 1em;
	}

	.obituary:is(::before, ::after) {
		display: none;
	}
}

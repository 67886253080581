/**
 * Separator
 * @project Instrumentalverein Tüddern
 * @author  Marc Görtz <https://marcgoertz.de/>
 */

@charset 'utf-8';

.separator {
	margin: 1.5rem 0;
	border-block-start: 2px solid var(--color-warmgray);
	clear: both;
}

.separator hr {
	display: none;
}

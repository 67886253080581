/**
 * Hidden Elements
 * @project Instrumentalverein Tüddern
 * @author  Marc Görtz <https://marcgoertz.de/>
 */

@charset 'utf-8';

/* templates */
template {
	display: none; /* hide the `template` element in IE 8/9/10/11, Safari, and Firefox < 22 */
}

/* hidden elements */
[hidden] {
	display: none !important; /* address `[hidden]` styling not present in IE 8/9/10 */
}

/* invisible elements */
.invisible {
	position: absolute;
	margin: -1px;
	padding: 0;
	border: 0 none;
	inline-size: 1px;
	block-size: 1px;
	overflow: hidden;
	clip: rect(0, 0, 0, 0);
}

.invisible:is(:focus, :active) {
	position: static;
	margin: 0;
	inline-size: auto;
	block-size: auto;
	overflow: visible;
	clip: auto;
}

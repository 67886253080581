/**
 * Quotes
 * @project Instrumentalverein Tüddern
 * @author  Marc Görtz <https://marcgoertz.de/>
 */

@charset 'utf-8';

blockquote {
	position: relative;
	margin: 1rem 0;
	padding: 0 0 0 2.5em;
}

blockquote::before {
	content: '“';
	position: absolute;
	inset-block-start: 0;
	inset-inline-start: 0;
	font-family: var(--font-stack-serif);
	font-size: 3em;
	line-height: 1.25;
	color: var(--color-red);
	pointer-events: none;
	user-select: none;
}

/**
 * Columns
 * @project Kickstart
 * @author  Marc Görtz <https://marcgoertz.de/>
 */

@charset 'utf-8';

@supports (display: grid) {
	.columns-grid {
		display: grid;
		grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
		grid-column-gap: 1rem;
	}

	@media (--large-devices) {
		.columns {
			column-count: 2;
			column-gap: 2rem;
		}

		.columns li {
			break-inside: avoid;
		}

		.columns > p:first-child {
			margin-block-start: 0;
		}
	}
}

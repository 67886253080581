/**
 * Tabs
 * @project Instrumentalverein Tüddern
 * @author  Marc Görtz <https://marcgoertz.de/>
 */

@charset 'utf-8';

.js .tabs-wrapper {
	display: flex;
	flex-direction: column;
	margin: 3rem 0;
	overflow: auto;
}

.js .tab-list {
	display: flex;
}

.js .tab-list .tab {
	margin: 0 0.5rem 0 0;
	padding: 0.5rem 1rem;
	border: 2px solid hsl(from var(--color-warmgray) h s 85%);
	border-block-end: 0;
	border-radius: 6px 6px 0 0;
	outline: 0;
	color: var(--color-gray);
	background-color: hsl(from var(--color-warmgray) h s 85%);
	box-sizing: border-box;
	cursor: pointer;
}

.js .tab-list .tab[aria-selected='true'] {
	position: relative;
	background-color: transparent;
}

.js .tab-list .tab[aria-selected='true']::after {
	content: '';
	position: absolute;
	inset-block-end: -2px;
	inset-inline: 0;
	border-block-end: 2px solid var(--color-snowwhite);
}

.js .tab-list .tab[aria-selected='true']:focus-visible {
	background-color: hsl(from var(--color-warmgray) h s 95%);
}

.js .tab-list .tab[aria-selected='true']:focus-visible::after {
	border-block-end-color: hsl(from var(--color-warmgray) h s 95%);
}

.js .tab-list .tab svg {
	max-inline-size: none;
	vertical-align: top;
}

.js .tab-list .tab[aria-selected='false'] path {
	fill: currentcolor;
}

.js .tab-pane {
	display: none;
	padding: 1rem;
	border: 2px solid hsl(from var(--color-warmgray) h s 85%);
	border-radius: 0 6px 6px;
	outline: 0;
	box-sizing: border-box;
}

.js .tab-pane:not([hidden]) {
	display: block;
}

@media (--large-devices) {
	.js .tabs-wrapper {
		margin-inline: -1rem;
	}
}

/**
 * Figures
 * @project Kickstart
 * @author  Marc Görtz <https://marcgoertz.de/>
 */

@charset 'utf-8';

figure {
	display: block; /* correct `block` display not defined in IE 8/9 */
	margin: 1rem 0;
	text-align: center;
}

figure img {
	margin: 0 auto;
}

figcaption {
	display: block; /* correct `block` display not defined in IE 8/9 */
	margin: 3px 0 0;
	font-size: 0.875rem;
	font-style: italic;
	text-align: inherit;
	color: hsl(from var(--color-gray) h s 40%);
}

.figure-start {
	float: left;
	margin: 0.375em 1em 0.375em 0;
	text-align: start;
}

.figure-end {
	float: right;
	margin: 0.375em 0 0.375em 1em;
	text-align: end;
}

.figure-center {
	margin: 0.375em 0;
	text-align: center;
}

/* iframes */
.responsive-iframe {
	position: relative;
	padding-block-start: 56.25%;
	overflow: hidden;
}

.responsive-iframe iframe {
	position: absolute;
	inset-block-start: 0;
	inset-inline-start: 0;
	border: 0;
	inline-size: 100%;
	block-size: 100%;
}

/* YouTube Lite */
lite-youtube {
	aspect-ratio: 16 / 9; /* matches YouTube player */
	max-inline-size: none;
}

@media (--medium-devices-max) {
	.figure-wide {
		margin-inline: -1rem;
	}

	.figure-wide figcaption {
		margin-inline: 1rem;
	}

	@supports (margin: env(safe-area-inset-left)) {
		.figure-wide {
			margin-inline: calc(env(safe-area-inset-left) * -1 - 1rem)
				calc(env(safe-area-inset-right) * -1 - 1rem);
		}

		.figure-wide figcaption {
			margin-inline: calc(env(safe-area-inset-left) + 1rem)
				calc(env(safe-area-inset-right) + 1rem);
		}
	}

	.sub-headline + .figure-wide {
		margin-block-start: calc(-2rem - 2px);
	}
}

@media (--large-devices) {
	figcaption {
		text-align: end;
	}

	.figure-center figcaption {
		text-align: center;
	}
}

@media print {
	.responsive-iframe {
		display: none;
		padding-block-start: 0;
		overflow: visible;
	}

	.responsive-iframe iframe {
		position: static;
		inline-size: auto;
		block-size: auto;
	}
}

/**
 * Images
 * @project Instrumentalverein Tüddern
 * @author  Marc Görtz <https://marcgoertz.de/>
 */

@charset 'utf-8';

/* images */
img {
	color: var(--color-gray);
	background: transparent;
	vertical-align: bottom;
	max-inline-size: 100%;
	block-size: auto;
	border: 0; /* removes border when inside `a` element in IE 8/9/10 */
}

/* bordered images  */
.bordered {
	border: 1px solid var(--color-warmgray);
	box-sizing: border-box;
}

/* SVG images */
svg {
	max-inline-size: 100%;
	block-size: auto;
}

svg:not(:root) {
	overflow: hidden; /* correct overflow not hidden in IE 9/10/11 */
}

/* canvas */
canvas {
	display: inline-block; /* correct `inline-block` display not defined in IE 8/9 */
}

/* Videos */
video {
	inline-size: 100%;
	block-size: auto;
}

/* orchestra line-up */
.line-up {
	margin: 3rem auto;
	text-align: center;
}

/* maps with selection */
.geomap {
	inline-size: 100%;
	block-size: 250px;
	z-index: 1;
}

/* license */
.license {
	color: var(--color-gray);
	background: transparent;
	font-size: 0.75em;
	line-height: 1.25rem;
}

.license a:is(:link, :visited, :hover, :focus, :active) {
	font-style: italic;
	color: inherit;
	background: transparent;
	text-decoration: none;
}

.license svg {
	display: inline-block;
	inline-size: 4rem;
	block-size: 1.25rem;
	fill: currentcolor;
	vertical-align: middle;
}

/* printed images */
@media print {
	img {
		max-inline-size: 100%; /* prevents images from being cut when they are larger than page width */
	}
}

/**
 * Links
 * @project Instrumentalverein Tüddern
 * @author  Marc Görtz <https://marcgoertz.de/>
 */

@charset 'utf-8';

/* links */
a:is(:link, :visited, :hover, :focus, :active) {
	color: var(--color-red);
	background-color: transparent;
	font-weight: bold;
	-webkit-tap-highlight-color: hsl(from var(--color-red) h s l / 15%);
}

a:is(:link, :visited) {
	text-decoration: underline;
}

a:is(:hover, :focus, :active) {
	text-decoration: none;
}

/* relation links */
.admin
	a:is([rel='prev'], [rel='next']):is(
		:link,
		:visited,
		:hover,
		:focus,
		:active
	) {
	text-decoration: none;
}

/* buttons looking like links */
.link-button {
	display: inline;
	margin: 0;
	padding: 0;
	border: 0;
	border-radius: 0;
	font: inherit;
	font-weight: bold;
	white-space: normal;
	text-decoration: underline;
	color: var(--color-red);
	background: none;
	cursor: pointer;
}

.link-button:is(:hover, :focus, :active) {
	text-decoration: none;
}

/* feed links */
.feed-link:is(:link, :visited, :hover, :focus, :active) {
	color: var(--color-warmgray);
	fill: currentcolor;
	text-decoration: none;
	transition: color 0.2s var(--timing-linear);
}

@media (--supports-hover) {
	.feed-link:hover {
		color: var(--color-orange);
	}
}

.feed-link:last-child {
	margin-inline-start: 0.25em;
}

.feed-icon {
	fill: var(--color-orange);
	vertical-align: middle;
}

/* geo links with optional map popup */
.geo-link:is(:link, :visited, :hover, :focus, :active) {
	color: currentcolor;
	font-weight: normal;
	text-decoration: none;
	border-block-end: 1px dotted var(--color-warmgray);
}

/* printed links */
@media print {
	a[href] {
		color: var(--color-red);
		background: var(--color-white);
		box-shadow: none;
	}
}

/**
 * Webmentions
 * @project Instrumentalverein Tüddern
 * @author  Marc Görtz <https://marcgoertz.de/>
 */

@charset 'utf-8';

.webmentions {
	margin: 0;
	padding: 0;
	list-style-type: none;
	font-size: 0.875em;
}

.webmentions li {
	margin: 0 0 1rem;
}

.webmentions img {
	float: left;
	margin-inline-end: 1ex;
	margin-block-start: -0.125em;
	inline-size: 1.5rem;
	block-size: 1.5rem;
	border-radius: 100%;
}

.webmentions svg {
	fill: currentcolor;
	vertical-align: -0.125em;
}

.webmentions li > .u-url {
	color: currentcolor;
	font-weight: normal;
	text-decoration: none;
}

.webmentions blockquote {
	margin-block-start: 0.5em;
	margin-inline-start: calc(1.5rem + 1ex);
	padding: 0.25em 0.5em;
	border-radius: 6px;
	background-color: hsl(from var(--color-warmgray) h s 85%);
}

.webmentions blockquote::before {
	content: '';
}

/**
 * Headlines
 * @project Instrumentalverein Tüddern
 * @author  Marc Görtz <https://marcgoertz.de/>
 */

@charset 'utf-8';

/* headlines */
h1,
h2,
h3,
h4 {
	font-weight: 900;
	font-family: var(--font-stack-serif);
}

h1 {
	margin: 0 0 2rem;
	font-size: 2.5em;
	line-height: 1.25;
	text-align: center;
	text-wrap: balance;
}

h2 {
	margin: 3rem 0 1rem;
	font-size: 1.875rem;
	line-height: 1.25;
	clear: both;
	text-wrap: balance;
}

h2::after {
	content: '';
	display: block;
	max-inline-size: 2.5rem;
	margin-block-start: 3px;
	border-block-start: 3px solid var(--color-red);
}

h3 {
	margin: 1rem 0;
	font-size: 1rem;
}

h4 {
	margin: 1.5em 0 1em;
	font-size: 1em;
	line-height: 1;
}

/* abbreviations in headlines */
:is(h1, h2, h3, h4) abbr[title] {
	border-block-end: 0;
}

/* sub-headlines */
.sub-headline {
	margin: -1rem 0 2rem;
	padding: 0 0 2rem;
	border-block-end: 2px solid var(--color-warmgray);
	font-size: 1.125rem;
	text-align: center;
}

.sub-headline .draft {
	color: var(--color-red);
	font-weight: bold;

	&::before {
		content: '– ';
	}

	&::after {
		content: ' –';
	}
}

/* captions */
.caption {
	margin: 3rem 0 0;
	padding: 0.25rem 0.5rem;
	border: 1px solid var(--color-warmgray);
	border-block-end: 0;
	color: var(--color-gray);
	background-color: var(--color-warmgray);
	font-family: inherit;
	font-size: 0.75rem;
	font-weight: normal;
	text-transform: uppercase;
	text-shadow: 1px 1px 1px var(--color-white);
}

.caption::after {
	display: none;
}

.caption + .striped {
	border: 1px solid var(--color-warmgray);
	border-block-start: 0;
}

@media (--large-devices) {
	h1 {
		font-size: 3.375em;
	}

	h3 {
		font-size: 1.125rem;
	}

	.sub-headline {
		margin-block-start: 0;
		font-size: 1.3125rem;
	}
}

/* page breaks */
@media print {
	h1,
	h2,
	h3,
	h4 {
		page-break-inside: avoid;
	}

	h2 {
		page-break-after: avoid;
	}
}

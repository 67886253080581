/**
 * Buttons
 * @project Instrumentalverein Tüddern
 * @author  Marc Görtz <https://marcgoertz.de/>
 */

@charset 'utf-8';

@keyframes pulse {
	0% {
		box-shadow: 0 0 0 0 hsl(from var(--color-red) h s l / 20%);
	}

	100% {
		box-shadow: 0 0 0 1.5rem hsl(from var(--color-red) h s l / 0%);
	}
}

/* buttons */
.btn,
.btn:is(:link, :visited, :hover, :focus, :active) {
	position: relative;
	display: inline-block;
	margin: 0; /* address margins set differently in Firefox 4+, Safari, and Chrome */
	padding: 0.5em 2em;
	border: 1px solid transparent;
	border-radius: 6px;
	overflow: visible; /* address `overflow` set to `hidden` in IE 8/9/10/11 */
	font: inherit; /* correct font properties not being inherited */
	font-weight: normal;
	text-decoration: none;
	text-align: center;
	text-transform: uppercase; /* correct `button` style inheritance in Firefox, IE 8/9/10/11, and Opera */
	color: var(--color-white);
	background-color: var(--color-red);
	fill: currentcolor;
	box-shadow: none;
	cursor: pointer; /* improve usability and consistency of cursor style between image-type `input` and others. */
	appearance: none;
	box-sizing: border-box;
	transition: background-color 0.2s var(--timing-linear);
}

@media (--supports-hover) {
	.btn:hover {
		background-color: var(--color-mediumred);
	}
}

/* small buttons */
.btn-small,
.btn-small:is(:link, :visited, :hover, :focus, :active) {
	font-size: 0.75em;
}

/* outline buttons */
.btn-outline,
.btn-outline:is(:link, :visited, :hover, :focus, :active) {
	border-color: currentcolor;
	color: var(--color-gray);
	background-color: transparent;
}

/* outline buttons */
.btn.delete,
.btn.delete:is(:link, :visited, :hover, :focus, :active) {
	color: var(--color-red);
}

/* disabled buttons */
.btn[disabled],
.btn[aria-disabled='true']:is(:link, :visited, :hover, :focus, :active) {
	color: var(--color-white);
	background-color: var(--color-warmgray);
	cursor: not-allowed;
	pointer-events: none;
}

.btn.btn-outline[disabled],
.btn.btn-outline[aria-disabled='true']:is(
		:link,
		:visited,
		:hover,
		:focus,
		:active
	) {
	border-color: currentcolor;
	color: var(--color-warmgray);
	background-color: transparent;
}

/* button rows (e.g. submit/reset in forms) */
.btn-row {
	display: grid;
	grid-template-columns: 1fr;
	gap: 0.5em;
	justify-content: space-between;
}

/* pulsating button */
.btn-pulse {
	animation: 2s var(--timing-linear) infinite pulse;
}

@media (--large-devices) {
	.btn-row {
		grid-template-columns: repeat(2, auto);
	}
}

@media (--medium-devices-max) {
	form .btn[type='submit']:only-of-type {
		inline-size: 100%;
	}
}

@media print {
	.btn,
	.btn:is(:link, :visited, :hover, :focus, :active) {
		color: inherit;
		background-color: transparent;
		border-color: currentcolor;
	}
}

/**
 * Header
 * @project Instrumentalverein Tüddern
 * @author  Marc Görtz <https://marcgoertz.de/>
 */

@charset 'utf-8';

header {
	grid-area: header;
	padding: 10px 0 0;
	block-size: 95px;
	text-align: center;
	box-sizing: border-box;
}

header a {
	display: inline-block;
	position: fixed;
	inset-inline: 100px;
	z-index: 101;
}

header svg {
	inline-size: 60px;
	block-size: 73px;
}

@media (--large-devices) {
	header {
		padding-block-start: 15px;
		padding-inline-end: 35px;
		block-size: auto;
		text-align: end;
	}

	header a {
		position: relative;
	}

	header svg {
		inline-size: 123px;
		block-size: 150px;
	}
}

@media print {
	header {
		padding: 10px 0 0;
		text-align: center;
	}

	header a {
		position: static;
	}

	header svg {
		inline-size: 60px;
		block-size: 73px;
	}
}

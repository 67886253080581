/**
 * Info Boxes
 * @project Instrumentalverein Tüddern
 * @author  Marc Görtz <https://marcgoertz.de/>
 */

@charset 'utf-8';

/* info boxes */
.info-box {
	display: flex;
	padding: 0.75rem 1.25rem;
	border-radius: 6px;
	fill: currentcolor;
}

.info-box svg {
	flex: 0 0 auto;
	margin-block-start: 0.125em;
	margin-inline-end: 0.5em;
	inline-size: 1.25em;
	block-size: 1.25em;
}

.info-box-success {
	color: var(--color-green);
	background-color: hsl(from var(--color-green) h s 90%);
}

.info-box-warning {
	color: var(--color-orange);
	background-color: hsl(from var(--color-orange) h s 90%);
}

.info-box-error {
	color: var(--color-red);
	background-color: hsl(from var(--color-red) h s 90%);
}

.info-box a:is(:link, :visited, :hover, :focus, :active) {
	color: currentcolor;
}

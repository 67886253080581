/**
* Truncated Elements
* @project Instrumentalverein Tüddern
* @author  Marc Görtz <https://marcgoertz.de/>
*/

@charset 'utf-8';

.js .block-truncated {
	position: relative;
	margin-block-start: 3rem;
	max-block-size: 25em;
	overflow: hidden;
	cursor: pointer;
}

.js .block-truncated::before {
	content: '';
	position: absolute;
	inset-block-end: 0;
	inset-inline: 0;
	block-size: 15em;
	background-image: linear-gradient(
		to bottom,
		hsl(from var(--color-snowwhite) h s l / 0%),
		var(--color-snowwhite)
	);
}

.js .block-truncated::after {
	content: 'mehr anzeigen';
	position: absolute;
	inset-block-end: 10%;
	inset-inline-start: 50%;
	padding: 0.5em 1.5em;
	border-radius: 2em;
	font-size: 0.75rem;
	color: var(--color-white);
	background-color: var(--color-red);
	transition: background-color 0.2s var(--timing-linear);
	transform: translateX(-50%);
	pointer-events: none;
}

@media (--supports-hover) {
	.js .block-truncated:hover::after {
		background-color: var(--color-mediumred);
	}
}

.js .block-truncated > h2:first-child {
	margin-block-start: 0;
}

@media (--medium-devices-max) {
	.js .block-truncated {
		margin-inline: -1rem;
		padding-inline: 1rem;
	}

	@supports (margin: env(safe-area-inset-left)) {
		.js .block-truncated {
			margin-inline: calc(env(safe-area-inset-left) * -1 - 1rem)
				calc(env(safe-area-inset-right) * -1 - 1rem);
			padding-inline: calc(env(safe-area-inset-left) + 1rem)
				calc(env(safe-area-inset-right) + 1rem);
		}
	}
}

@media print {
	.js .block-truncated {
		max-block-size: none;
		overflow: visible;
	}

	.js .block-truncated:is(::before, ::after) {
		display: none;
	}
}

/**
 * Forms
 * @project Instrumentalverein Tüddern
 * @author  Marc Görtz <https://marcgoertz.de/>
 */

@charset 'utf-8';

/* text inputs */
input[type='text'],
input[type='password'],
input[type='email'],
input[type^='date'],
input[type='url'],
output,
textarea {
	display: block;
	margin: 0;
	padding: 1.5em 0.75em 0.25em;
	border: 0;
	border-block-end: 2px solid hsl(from var(--color-gray) h s 50%);
	border-radius: 0;
	outline: 0;
	inline-size: 100%;
	block-size: calc(3rem + 2px);
	font-family: inherit;
	font-size: 1rem;
	line-height: 1.25;
	color: var(--color-gray);
	background-color: var(--color-white);
	background-clip: padding-box; /* removes inner shadow on iOS */
	box-shadow: none; /* removes validation outline in Firefox */
	box-sizing: border-box;
}

input[type='text']:focus,
input[type='password']:focus,
input[type='email']:focus,
input[type^='date']:focus,
input[type='url']:focus,
select:focus + output,
textarea:focus {
	border-block-end-color: var(--color-red);
}

textarea {
	block-size: auto;
	overflow-y: scroll;
}

/* search inputs */
input[type='search'] {
	display: block;
	margin: 0;
	padding: 0.5em 1em 0.5em 2.5em;
	border: 2px solid var(--color-warmgray);
	border-radius: 2.5rem;
	outline: 0;
	inline-size: 100%;
	block-size: 2.5rem;
	font-family: inherit;
	font-size: 1rem;
	line-height: 1.25;
	color: var(--color-gray);
	background-color: var(--color-white);
	background-clip: padding-box; /* removes inner shadow on iOS */
	box-shadow: none; /* removes validation outline in Firefox */
	box-sizing: border-box;
}

input[type='search']:focus {
	border-color: currentcolor;
}

/* date inputs */
input[type^='date'] {
	min-block-size: 50px; /* fix inputs with an empty value */
	appearance: none; /* remove ugly iOS Safari date input look */
}

input[type^='date']::-webkit-date-and-time-value {
	text-align: start; /* fix value alignment in iOS Safari 15 */
}

/* checkboxe and radio buttons */
input[type='checkbox'],
input[type='radio'] {
	position: absolute;
	inset-block-start: 0.25ex;
	inset-inline-start: 0;
	margin: 0;
	padding: 0;
	border: 0;
	inline-size: 1.25em;
	block-size: 1.25em;
	font-size: inherit;
	overflow: hidden;
	opacity: 0;
	cursor: pointer;
}

input[type='checkbox'] + .box {
	position: relative;
	display: inline-block;
	margin: 0.25ex 0.75ex 0 0;
	inline-size: 1.25em;
	block-size: 1.25em;
	border: 1px solid hsl(from var(--color-gray) h s 50%);
	color: var(--color-gray);
	background-color: var(--color-white);
	box-sizing: border-box;
	cursor: pointer;
}

input[type='checkbox'] + .box::after {
	content: '';
	position: absolute;
	inset-block-start: 0;
	inset-inline-end: 0;
	inline-size: 100%;
	block-size: 100%;
	background-color: var(--color-red);
	opacity: 0;
	pointer-events: none;
	transition: width 0.25s var(--timing-linear);
}

input[type='radio'] + .box {
	position: relative;
	display: inline-block;
	margin: 0.25ex 0.75ex 0 0;
	inline-size: 1.25em;
	block-size: 1.25em;
	border: 1px solid hsl(from var(--color-gray) h s 50%);
	border-radius: 100%;
	color: var(--color-gray);
	background-color: var(--color-white);
	box-sizing: border-box;
	cursor: pointer;
}

input[type='radio'] + .box::after {
	content: '';
	position: absolute;
	inset-block-start: 25%;
	inset-inline-start: 25%;
	inline-size: 50%;
	block-size: 50%;
	border-radius: 100%;
	background-color: currentcolor;
	opacity: 0;
	pointer-events: none;
	transform: scale(0.55);
	transition: transform 0.25s var(--timing-linear);
}

input[type='checkbox']:focus + .box,
input[type='radio']:focus + .box {
	border-color: var(--color-red);
}

input[type='checkbox'][disabled] + .box,
input[type='radio'][disabled] + .box {
	border-color: transparent !important;
	background-color: var(--color-warmgray) !important;
}

input[type='checkbox']:checked + .box {
	border-color: transparent;
	color: var(--color-white);
	background-color: var(--color-red);
	background-image: inline('check.svg');
	background-repeat: no-repeat;
	background-position: center;
	background-size: 90%;
}

input[type='checkbox']:checked + .box::after {
	opacity: 1;
	inline-size: 0;
}

input[type='radio']:checked + .box::after {
	opacity: 1;
	transform: scale(1);
}

/* file inputs */
input[type='file'] {
	display: block;
	inline-size: 100%;
	padding: 1rem;
	border: 2px dotted hsl(from var(--color-gray) h s l / 25%);
	border-radius: 1em;
	box-sizing: border-box;
	text-align: center;
	cursor: pointer;

	&:is(:hover, :focus) {
		border-color: var(--color-red);
		outline: 0;
	}
}

/* dropdowns */
.dropdown {
	display: block;
	position: relative;

	&::after {
		content: '';
		position: absolute;
		inset-block-start: calc(50% - 0.25em);
		inset-inline-end: 1em;
		border-block-start: 0.5em solid;
		border-inline: 0.5em solid transparent;
		border-block-end: 0;
	}

	select {
		position: absolute;
		inset-block-start: 0;
		inset-inline-start: 0;
		z-index: 1;
		inline-size: 100%;
		block-size: 100%;
		cursor: pointer;
		opacity: 0;
		appearance: none;
	}
}

/* disabled fields */
input[disabled] {
	cursor: not-allowed;
}

/* fieldsets */
fieldset {
	margin: 0;
	padding: 0;
	border: 0;
}

legend {
	position: absolute;
	margin: -1px;
	padding: 0;
	border: 0 none;
	inline-size: 1px;
	block-size: 1px;
	overflow: hidden;
	clip: rect(0, 0, 0, 0);
}

/* lists */
form ol {
	padding: 0;
	list-style: none;
}

form ol li {
	position: relative;
	margin-block-end: 1rem;
}

form .half {
	display: flex;
	justify-content: space-between;
}

form .half li {
	flex: 1 0 auto;
	margin-block-end: 0;
	max-inline-size: 48%;
}

/* labels */
form label[for] {
	position: absolute;
	inset-block-start: 0.25rem;
	inset-inline-start: 0.75rem;
	z-index: 1;
	font-size: 0.75rem;
}

form .toolbar ~ label[for] {
	inset-block-start: calc(2rem + 1px);
	inset-inline-end: 1rem;
	padding-block-start: 0.25rem;
	background-color: var(--color-white);
}

form label[for^='image'],
form label[for^='upload'] {
	display: block;
	position: static;
}

form label[for='search'] {
	inset-block-start: 0.625rem;
	inset-inline-start: 1rem;
	inline-size: 1.25rem;
	block-size: 1.25rem;
	background-image: inline('icons/search.svg');
	background-repeat: no-repeat;
	background-size: contain;
	text-indent: -100em;
	overflow: hidden;
}

/* errors */
form span[id$='error'] {
	display: block;
	font-weight: bold;
	font-size: 0.75rem;
	color: var(--color-red);
}

/* descriptions */
form ol small {
	display: block;
	margin-block-start: 2px;
	font-size: 0.75em;
}

/* code in forms */
form code {
	padding: 0;
	border-radius: 0;
	text-shadow: none;
	color: var(--color-red);
	background-color: transparent;
}

/* search forms */
form[role='search'] {
	position: relative;
}

form[role='search'] div {
	display: flex;
	flex-direction: row-reverse;
	justify-content: space-between;
	margin: 0.25rem 1.25rem;
}

form[role='search'] div p {
	margin: 0;
}

form[role='search'] ul {
	flex-grow: 1;
	display: flex;
	margin: 0;
	padding: 0;
	list-style-type: none;
}

form[role='search'] ul li {
	margin-inline-end: 1ex;
}

form[role='search'] a:is(:link, :visited, :hover, :focus, :active) {
	font-size: 0.875em;
	font-weight: normal;
}

/* uploads */
.uploaded {
	display: block;
	margin: 3px 0;
}

/* selections (wrapper for radio and check boxes) */
.selection {
	display: flex;
	position: relative;

	.box {
		flex-grow: 0;
		flex-shrink: 0;
	}
}

.selection-group {
	display: flex;
	flex-flow: column wrap;
	gap: 0.5rem;
}

/* newsletter form */
.newsletter-preview {
	font-family: -apple-system, BlinkMacSystemFont, sans-serif;
	font-size: 24px;
	fill: hsl(240deg 1.18% 33.33%);
}

/* details */
.faq {
	position: relative;
	margin: 1rem 0;
	padding-block-end: 0.25em;
	border-block-end: 1px solid var(--color-warmgray);
}

.faq summary {
	padding-block-start: 0.25em;
	padding-inline-end: 1.5em;
	outline: 0;
	font-weight: bold;
}

.faq summary svg {
	position: absolute;
	inset-block-start: 0.25em;
	inset-inline-end: 0;
	fill: currentcolor;
	transition: transform 0.3s var(--timing-ease-out);
}

.faq[open] summary svg {
	transform: rotate(45deg);
}

.faq summary:is(:hover, :focus) {
	color: var(--color-red);
}

.faq summary::marker {
	content: '';
}

.faq summary::-webkit-details-marker {
	display: none;
}

.faq + .highlight {
	margin-block-start: 2rem;
}

@media (--large-devices) {
	.selection-group {
		flex-direction: row;
		gap: 1.5rem;
	}
}

.input-icon {
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	inset-block-start: calc((3rem + 2px) / 2);
	inset-inline-end: 0.5em;
	inline-size: 1.25em;
	block-size: 1.25em;
	fill: currentcolor;
}

.input-icon[role='button'] {
	cursor: pointer;
}

.input-icon-success {
	color: var(--color-green);
}

/* Password strength */
.password-strength {
	position: absolute;
	inset-block-start: 3rem;
	inset-inline-start: 0;
	border-inline-end: 0.5em solid hsl(from var(--color-warmgray) h s 85%);
	inline-size: 0;
	block-size: 2px;
	overflow: hidden;
	transition: all 0.2s var(--timing-linear);
}

.password-strength.password-strength-weak {
	background-color: hsl(from var(--color-red) h s 60%);
}

.password-strength.password-strength-medium {
	background-color: var(--color-orange);
}

.password-strength.password-strength-strong {
	background-color: hsl(from var(--color-green) h s 40%);
}

.password-strength[value='0'] {
	display: none;
}

.password-strength-text {
	display: inline-block;
	margin-inline-start: 0.5em;
	padding: 0 1em;
	border-radius: 1em;
	color: var(--color-white);
}

.password-strength-text.password-strength-text-weak {
	background-color: hsl(from var(--color-red) h s 60%);
}

.password-strength-text.password-strength-text-medium {
	background-color: var(--color-orange);
}

.password-strength-text.password-strength-text-strong {
	background-color: hsl(from var(--color-green) h s 40%);
}

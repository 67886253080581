/**
 * Articles
 * @project Instrumentalverein Tüddern
 * @author  Marc Görtz <https://marcgoertz.de/>
 */

@charset 'utf-8';

.entry-title {
	hyphens: auto;
}

.entry-title a:is(:link, :visited, :hover, :focus, :active) {
	color: inherit;
	font-weight: inherit;
}

.entry-teaser {
	position: relative;
	margin-block-start: 1rem;
	padding-block-start: 1.5rem;
}

.entry-teaser ~ .entry-teaser {
	margin-block-start: 3rem;
}

.entry-teaser .entry-title {
	margin-block-start: 0;
}

.entry-teaser .entry-date {
	position: absolute;
	inset-block-start: 0;
	inset-inline-start: 0;
	font-size: 0.875rem;
	line-height: 1.75;
}

.entry-footer {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 0.5rem;
	margin: 1rem 0;
}

.entry-buttons {
	flex-shrink: 1;
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	inline-size: 100%;

	.btn-page-prev {
		svg {
			transform: rotate(0.25turn);
		}
	}

	.btn-page-next {
		order: 1;

		svg {
			transform: rotate(0.75turn);
		}
	}
}

.entry-tags {
	flex-grow: 1;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	margin: 0;
	padding: 0;
	font-size: 0.75rem;
	list-style-type: none;
}

.entry-tags li {
	position: relative;
	margin: 0 0 0 1ex;
	padding: 0 0 0.5em;
	white-space: nowrap;
}

.entry-tags li::before {
	content: '#';
}

.entry-author {
	display: none;
}

@media (--medium-devices) {
	.entry-buttons {
		justify-content: start;
		gap: 1px;
		inline-size: auto;

		.btn:not([class*='btn-page']) {
			margin-inline-start: 1ex;
		}

		.btn-page-prev,
		.btn-page-prev:is(:link, :visited, :hover, :focus, :active) {
			padding-inline: 1rem;
			border-start-end-radius: 0;
			border-end-end-radius: 0;
		}

		.btn-page-next,
		.btn-page-next:is(:link, :visited, :hover, :focus, :active) {
			order: 0;
			padding-inline: 1rem;
			border-start-start-radius: 0;
			border-end-start-radius: 0;
		}
	}
}

@media (--large-devices) {
	.entry-footer {
		flex-direction: row-reverse;
	}

	.entry-tags {
		justify-content: end;
	}
}

@media print {
	.entry-buttons {
		display: none;
	}
}

/**
 * Slider
 * @project Instrumentalverein Tüddern
 * @source Tiny Slider <https://github.com/ganlanyuan/tiny-slider>
 * @author  Marc Görtz <https://marcgoertz.de/>
 */

/* stylelint-disable selector-class-pattern -- Tiny Slider library styles */

@charset 'utf-8';

.tns-outer {
	position: relative;
	margin-block: 1rem;

	figure {
		margin-block: 0;
	}
}

.tns-outer [hidden] {
	display: none !important;
}

.tns-outer [aria-controls],
.tns-outer [data-action] {
	cursor: pointer;
}

.tns-inner {
	background-color: var(--color-white);
}

.tns-slider {
	transition: all 0s;
}

.tns-slider > .tns-item {
	box-sizing: border-box;
}

.tns-horizontal.tns-subpixel {
	white-space: nowrap;
}

.tns-horizontal.tns-subpixel > .tns-item {
	display: inline-block;
	vertical-align: middle;
	white-space: normal;
}

.tns-horizontal.tns-no-subpixel::after {
	content: '';
	display: table;
	clear: both;
}

.tns-horizontal.tns-no-subpixel > .tns-item {
	float: left;
}

.tns-horizontal.tns-carousel.tns-no-subpixel > .tns-item {
	margin-inline-end: -100%;
}

.tns-no-calc {
	position: relative;
	inset-inline-start: 0;
}

.tns-gallery {
	position: relative;
	inset-inline-start: 0;
	min-block-size: 1px;
}

.tns-gallery > .tns-item {
	position: absolute;
	inset-inline-start: -100%;
	transition:
		transform 0s,
		opacity 0s;
}

.tns-gallery > .tns-slide-active {
	position: relative;
	inset-inline-start: auto !important;
}

.tns-gallery > .tns-moving {
	transition: all 0.25s;
}

.tns-autowidth {
	display: inline-block;
}

.tns-lazy-img {
	transition: opacity 0.6s;
	opacity: 0.6;
}

.tns-lazy-img.tns-complete {
	opacity: 1;
}

.tns-ah {
	transition: height 0s;
}

.tns-ovh {
	overflow: hidden;
}

.tns-visually-hidden {
	position: absolute;
	inset-inline-start: -10000em;
}

.tns-transparent {
	opacity: 0;
	visibility: hidden;
}

.tns-fadeIn {
	opacity: 1;
	z-index: 0;
}

.tns-normal,
.tns-fadeOut {
	opacity: 0;
	z-index: -1;
}

.tns-vpfix {
	white-space: nowrap;
}

.tns-vpfix > div,
.tns-vpfix > li {
	display: inline-block;
}

.tns-t-subp2 {
	margin: 0 auto;
	inline-size: 310px;
	position: relative;
	block-size: 10px;
	overflow: hidden;
}

.tns-t-ct {
	inline-size: calc(100% * 70 / 3);
	position: absolute;
	inset-inline-end: 0;
}

.tns-t-ct::after {
	content: '';
	display: table;
	clear: both;
}

.tns-t-ct > div {
	inline-size: calc(100% / 70);
	block-size: 10px;
	float: left;
}

.tns-nav {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 0.5rem;
	margin-block: 0.5rem 1rem;

	button {
		margin: 0;
		padding: 0;
		border: 0;
		border-radius: 100%;
		block-size: 0.5rem;
		inline-size: 0.5rem;
		background: none;
		background-color: var(--color-warmgray);
		font: inherit;

		&.tns-nav-active {
			background-color: var(--color-gray);
		}
	}
}

.tns-controls {
	button {
		position: absolute;
		inset-block-start: calc(50% - 0.75rem);
		z-index: 1;
		margin: 0;
		padding: 0;
		border: 0;
		block-size: 1.5rem;
		inline-size: 1.5rem;
		background: none;
		font: inherit;
		overflow: hidden;
		text-indent: -1000em;
		appearance: none;

		&::after {
			content: '';
			position: absolute;
			inset: 0;
			border-radius: 100%;
			background-color: hsl(from var(--color-white) h s l / 50%);
			clip-path: polygon(
				0 0,
				100% 0,
				100% 25%,
				48% 25%,
				25% 50%,
				48% 75%,
				66% 75%,
				43% 50%,
				66% 25%,
				100% 0,
				100% 100%,
				0 100%
			);
		}

		&[data-controls='prev'] {
			inset-inline-start: 0.5rem;
		}

		&[data-controls='next'] {
			inset-inline-end: 0.5rem;
			transform: rotate(0.5turn);
		}

		&[disabled] {
			display: none;
		}
	}
}

@media (--medium-devices-max) {
	@supports (margin: env(safe-area-inset-left)) {
		.tns-outer {
			margin-inline: calc(env(safe-area-inset-left) * -1 - 1rem)
				calc(env(safe-area-inset-right) * -1 - 1rem);

			.figure-wide {
				margin-inline: 0;
			}
		}
	}
}

/**
 * Typography
 * @project Instrumentalverein Tüddern
 * @author  Marc Görtz <https://marcgoertz.de/>
 */

@charset 'utf-8';

/* fonts */
@font-face {
	src:
		local('Merriweather Black'),
		local('Merriweather-Black'),
		resolve('merriweather-black.woff2') format('woff2'),
		resolve('merriweather-black.woff') format('woff');
	font-family: Merriweather;
	font-weight: 900;
	font-style: normal;
	font-display: swap;
}

@font-face {
	src:
		local('Montserrat Regular'),
		local('Montserrat-Regular'),
		resolve('montserrat-regular.woff2') format('woff2'),
		resolve('montserrat-regular.woff') format('woff');
	font-family: Montserrat;
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	src:
		local('Montserrat Italic'),
		local('Montserrat-Italic'),
		resolve('montserrat-italic.woff2') format('woff2'),
		resolve('montserrat-italic.woff') format('woff');
	font-family: Montserrat;
	font-weight: normal;
	font-style: italic;
	font-display: fallback;
}

@font-face {
	src:
		local('Montserrat SemiBold'),
		local('Montserrat-SemiBold'),
		resolve('montserrat-bold.woff2') format('woff2'),
		resolve('montserrat-bold.woff') format('woff');
	font-family: Montserrat;
	font-weight: bold;
	font-style: normal;
	font-display: fallback;
}

/* typographic defaults */
html {
	font: 1em/1.5 var(--font-stack);
	text-size-adjust: 100%; /* prevents text size adjust after orientation change */
	quotes: '„' '“' '‚' '‘';
}

/* paragraphs and addresses */
p,
address {
	margin: 0.625rem 0 0;
	text-wrap: pretty;
}

/* sub and sup */
sub,
sup {
	position: relative;
	font-size: 75%;
	line-height: 0;
	vertical-align: baseline;
}

sup {
	inset-block-start: -0.5em;
}

sub {
	inset-block-end: -0.25em;
}

/* summaries */
summary {
	cursor: pointer;
}

/* alternate glyphs */
.alternate {
	display: inline;
	color: hsl(from var(--color-gray) h s 50%);
	font-style: italic;
	font-weight: normal;
	font-size: 1em;
	font-family:
		'Warnock Pro', 'Goudy Old Style', Palatino, 'Book Antiqua', Georgia,
		serif;
}

/* forced block-level element */
.block {
	display: block;
}

/* tag */
.tag,
.tag:is(:link, :visited, :hover, :focus, :active) {
	display: inline-block;
	padding: 0.25em 0.5em;
	border-radius: 1em;
	font-size: 0.75em;
	font-weight: normal;
	text-decoration: none;
	color: var(--color-gray);
	background-color: hsl(from var(--color-warmgray) h s 85%);
}

.tag:is(:hover, :focus, :active) {
	color: var(--color-red);
}

/* current color SVG helper */
.current-color {
	fill: currentcolor;
}

/* alignments */
.align-center {
	text-align: center;
}

.align-right {
	text-align: end;
}

/* quiet (softened) typography */
.quiet {
	color: hsl(from var(--color-gray) h s 50%);
	font-size: 0.75em;
}

/* forced no wrapping */
.no-break {
	white-space: nowrap;
}

/* forced no balance */
.no-balance {
	text-wrap: unset;
}

/* stars */
.star {
	font-size: 0.75em;
	vertical-align: 0.125em;
	color: var(--color-gold);
}

.star[title] {
	cursor: help;
}

/* vertical alignment helpers */
.va-baseline {
	vertical-align: -0.1875em;
}

.va-up-mini {
	vertical-align: -0.125em;
}

.va-up-micro {
	vertical-align: -0.0625em;
}

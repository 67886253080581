/**
 * Abbreviations
 * @project Instrumentalverein Tüddern
 * @author  Marc Görtz <https://marcgoertz.de/>
 */

@charset 'utf-8';

abbr[title] {
	border-block-end: 0;
	text-decoration: none;
	white-space: nowrap;
	cursor: help;
}

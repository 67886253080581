/**
 * Variables (Custom Properties)
 * @project Instrumentalverein Tüddern
 * @author  Marc Görtz <https://marcgoertz.de/>
 */

@charset 'utf-8';

:root {
	/* Font stacks */
	--font-stack: montserrat, system-ui;
	--font-stack-serif: merriweather, georgia, serif;
	--font-stack-monospace: 'Fira Code', monospace;

	/* Gray scale colors */
	--color-black: hsl(0deg 0% 0%);
	--color-gray: hsl(0deg 0% 25%);
	--color-warmgray: hsl(60deg 7% 73%);
	--color-snowwhite: hsl(0deg 0% 98%);
	--color-white: hsl(0deg 0% 100%);

	/* Other colors */
	--color-gold: hsl(40deg 38% 57%);
	--color-red: hsl(358deg 68% 43%);
	--color-mediumred: hsl(358deg 52% 28%);
	--color-darkred: hsl(0deg 100% 10%);
	--color-green: hsl(134deg 61% 21%);
	--color-yellow: hsl(48deg 100% 50%);
	--color-orange: hsl(33deg 100% 50%);
	--color-shadow: hsl(0deg 0% 0% / 25%);

	/* Social media colors */
	--color-facebook: hsl(214deg 89% 52%);
	--color-instagram: hsl(349deg 75% 57%);
	--color-youtube: hsl(0deg 100% 50%);

	/* Timing functions */
	--timing-linear: linear;
	--timing-ease-out: ease-out;
	--timing-fast-ease-out: cubic-bezier(0.04, 0.04, 0.12, 0.96);
	--timing-fast-ease-in-ease-out: cubic-bezier(0.52, 0.16, 0.24, 1);
	--timing-bounce: cubic-bezier(0.215, 0.61, 0.355, 1);
}

@custom-media --small-devices (width >= 375px);
@custom-media --small-devices-max (width < 475px);
@custom-media --medium-devices (width >= 475px);
@custom-media --medium-devices-max (width < 928px);
@custom-media --large-devices (width >= 928px);
@custom-media --supports-hover (hover: hover);
@custom-media --supports-p3 (color-gamut: p3);

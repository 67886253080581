/**
 * Layout
 * @project Instrumentalverein Tüddern
 * @author  Marc Görtz <https://marcgoertz.de/>
 */

@charset 'utf-8';

/* root elements */
html {
	overflow-y: scroll; /* provides scrollbars to avoid jumping centered layouts */
	color: var(--color-gray);
	background-color: var(--color-black);
	scroll-behavior: smooth;
}

body {
	margin: 0;
	padding: 0;
	background-color: var(--color-snowwhite);
}

/* main content */
main {
	display: block; /* correct `block` display not defined in IE 8/9/10/11. */
	margin: 0 1rem 3rem;
	background-color: var(--color-snowwhite);
}

@supports (padding: env(safe-area-inset-left)) {
	main {
		padding: 0 env(safe-area-inset-right) 0 env(safe-area-inset-left);
	}
}

/* scroll targets (works even in Safari since scroll-margin-top does not work there) */
*:target::before {
	content: '';
	display: block;
	position: relative;
	inset-block-end: 0;
	inline-size: 0;
	block-size: 3em; /* approx. size of the header */
	margin-block-start: -3em;
	pointer-events: none;
}

.sticky ~ :target::before {
	block-size: 6em; /* approx. size of the header plus sticky navigation */
	margin-block-start: -6em;
}

/** Floating action button */
.floating-action-button {
	position: fixed;
	inset-block-end: 1rem;
	inset-inline-end: 1rem;
	z-index: 1;
	padding: 0.25rem;
	border-radius: 100%;
	box-shadow: 0 1px 2px hsl(from var(--color-gray) h s l / 60%);
	color: var(--color-gray);
	background-color: var(--color-white);
	transition: all 0.2s var(--timing-fast-ease-out);
	animation: 0.75s var(--timing-bounce) 2s backwards bounce-in;

	a:is(:link, :visited, :hover, :focus, :active) {
		display: block;
	}

	svg {
		vertical-align: bottom;
	}

	span {
		position: absolute;
		inset-block-end: calc(100% + 0.5rem);
		inset-inline-end: 0;
		padding: 0.25rem 0.5rem;
		border-radius: 1rem;
		box-shadow: 0 1px 2px hsl(from var(--color-gray) h s l / 60%);
		color: var(--color-gray);
		background-color: var(--color-white);
		white-space: nowrap;
		animation: 0.75s var(--timing-bounce) 2.25s backwards bounce-in;
		pointer-events: none;
	}

	@media (--supports-hover) {
		&:hover {
			background-color: var(--color-snowwhite);
			box-shadow: 0 3px 8px hsl(from var(--color-gray) h s l / 60%);
		}
	}

	@supports (inset-inline-end: env(safe-area-inset-right)) {
		inset-block-end: calc(env(safe-area-inset-bottom) + 1rem);
		inset-inline-end: calc(env(safe-area-inset-right) + 1rem);
	}
}

@media (--large-devices) {
	body {
		background-image: linear-gradient(
			to bottom,
			var(--color-darkred),
			var(--color-mediumred) 48px,
			var(--color-snowwhite) 49px
		);
	}

	@supports (
		background-image: linear-gradient(to bottom, black, white 10px)
	) {
		body {
			background-color: var(--color-darkred);
		}
	}

	body > .wrapper {
		display: grid;
		grid-template:
			'header main' 230px
			'nav main' auto
			'sponsoring main' 1fr
			/ 250px 1fr;
		margin: 0 auto;
		max-inline-size: 1200px;
	}

	main {
		grid-area: main;
		margin-block-start: 125px;
		margin-inline: 125px;
	}

	main + aside {
		grid-area: sponsoring;
		margin: 50px 37px 2rem 0;
		text-align: end;
	}

	*:target::before {
		block-size: 5em; /* height of the header */
		margin-block-start: -5em;
	}
}

/* printed page layout */
@media print {
	html {
		orphans: 4;
		widows: 2;
	}

	html,
	body {
		inline-size: 100%;
		max-inline-size: none;
		margin: 0;
		padding: 0;
		color: var(--color-black);
		background: var(--color-white);
	}

	body > .wrapper {
		display: block;
		max-inline-size: 1200px;
	}

	main {
		margin: 0;
	}
}

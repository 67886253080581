/**
 * Tables
 * @project Instrumentalverein Tüddern
 * @author  Marc Görtz <https://marcgoertz.de/>
 */

@charset 'utf-8';

table {
	margin: 1rem 0;
	border: 1px solid var(--color-warmgray);
	border-collapse: collapse;
	border-spacing: 0;
	line-height: 1.25;
	inline-size: 100%;
}

caption {
	display: none;
}

th {
	padding: 0.25rem 0.5rem;
	color: var(--color-gray);
	background-color: var(--color-warmgray);
	font-size: 0.75rem;
	font-weight: normal;
	text-transform: uppercase;
	text-align: start;
	text-shadow: 1px 1px 1px var(--color-white);
}

th[scope='row'] {
	color: inherit;
	background-color: transparent;
	font-size: inherit;
	text-transform: inherit;
	text-align: inherit;
	text-shadow: inherit;
}

td {
	padding: 0.25rem 0.5rem;
}

tr:nth-child(even) td {
	background-color: hsl(from var(--color-warmgray) h s 95%);
}

table time {
	display: block;
	font-size: 0.75em;
	font-weight: bold;
}

table small {
	font-size: 0.75em;
	color: var(--color-red);
}

table .btn,
table .btn:is(:link, :visited, :hover, :focus, :active) {
	padding-inline: 0.5rem;
	inline-size: auto !important;
}

table .quiet {
	font-size: unset;
}

.tabular-nums {
	font-variant: tabular-nums;
}

.admin td.no-break {
	inline-size: 110px;
}

.admin table form {
	display: inline;
}
